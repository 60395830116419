<template>
  <section>
    <div class="contentsWidth mx-auto mt-4">
      <b-container fluid class="itemWrap">
        <b-row class="mt-2">
          <b-col sm="3" class="cantEditLabel">
            <label>氏名</label>
          </b-col>
          <div class="p-20 col-sm-8">
            <span>{{ sei }} {{ mei }}</span>
          </div>
        </b-row>

        <b-row class="mt-3">
          <b-col sm="3" class="cantEditLabel">
            <label>メールアドレス</label>
          </b-col>
          <div class="p-20 col-sm-8">
            <span>{{ mail_address }}</span>
          </div>
        </b-row>

        <b-row class="mt-3">
          <b-col sm="3" class="cantEditLabel">
            <label>所属機関</label>
          </b-col>
          <div class="p-20 col-sm-8">
            <span>{{ affiliated_institution }}</span>
          </div>
        </b-row>

        <b-row class="mt-3">
          <b-col sm="3" class="cantEditLabel">
            <label>役職</label>
          </b-col>
          <div class="p-20 col-sm-8">
            <span>{{ position }}</span>
          </div>
        </b-row>

        <b-row class="mt-3">
          <b-col sm="3" class="cantEditLabel">
            <label>ログイン可能期間</label>
          </b-col>
          <div class="p-20 col-sm-8">
            <span v-if="allowed_from">{{ allowed_from }} ～ {{ allowed_to }}</span>
          </div>
        </b-row>

        <b-row class="mt-3 permission">
          <b-col sm="3" class="cantEditLabel">
            <label>権限</label>
          </b-col>
          <span class="" v-for="(item) in authes" v-bind:key="item.value">
            {{authItems[item]}}
          </span>
        </b-row>
      </b-container>
    </div>

    <div class="bottonArea mt-4 flex flexCenter">
      <b-button class="btn btn-lg mr-2 bold" to="/cms/cmsUser/list">一覧に戻る</b-button>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    sei: { type: String },
    mei: { type: String, default: '' },
    mail_address: { type: String },
    affiliated_institution: { type: String },
    position: { type: String },
    allowed_from: { type: String },
    allowed_to: { type: String },
    authes: { type: Array },
    authItems: { type: Array },
  },
};
</script>

<style scoped>
  .itemWrap {
    font-size: 16px;
  }
  .permission span:not(:last-child)::after {
    content:"、";
  }
  .permission > span {
    padding-left: 15px;
  }
  .cantEditLabel {
    width: 180px;
    max-width: 210px;
    font-weight: bold;
    white-space: pre-line;
  }
</style>
