<template>
  <div class="cmsUserEdit">
    <div class="subject">
      <p class="title">管理者 - {{ title }}</p>
    </div>
    <section v-if="hasGroupAuth">
      <div class="contentsWidth mx-auto mt-4">
        <b-form class="mt-4" autocomplete="off">
          <v-form-name
            label="氏名"
            maxLength="50"
            :left.sync="sei"
            :right.sync="mei"
            :placeholders="{left: '例）山田' , right: '例）太郎'}"
            :isRequired="true"
            :errors="{left: errors.sei || '', right: errors.mei || ''}"
          >
          </v-form-name>
          <hr>

          <v-form-name
            label="ふりがな"
            maxLength="100"
            :left.sync="kana_sei"
            :right.sync="kana_mei"
            :placeholders="{left: '例）やまだ' , right: '例）たろう'}"
            :isRequired="true"
            :errors="{left: errors.kana_sei || '', right: errors.kana_mei || ''}"
          >
          </v-form-name>
          <hr>

          <div v-if=id class="container-fluid itemWrap">
            <div class="row mt-2">
              <div class="preEmail">
                <label>現在のメールアドレス</label>
              </div>
              <div class="p-20 col-sm-8">
                <span>{{ currentEmail }}</span>
              </div>
            </div>
          </div>
          <b-form-checkbox
            v-if=id
            v-model="changeEmail"
          >
            メールアドレスを変更する
          </b-form-checkbox>
          <v-form-text
            itemStyle="mt-2"
            label="メールアドレス"
            maxLength="256"
            type="email"
            labelGridClass="inputLabel"
            placeholder="例）○○○○○○○○@abcd.co.jp"
            :isRequired="true"
            :class="!changeEmail && (id !== null) && 'grayscale'"
            :disabled="!changeEmail && (id !== null)"
            :errMessage="errors.mail_address || []"
            v-model="mail_address"
          >
          </v-form-text>
          <hr>

          <b-form-checkbox
            v-if=id
            v-model="changePassword"
          >
            パスワードを変更する
          </b-form-checkbox>
          <v-form-text
            itemStyle="mt-2 attention"
            label="パスワード"
            maxLength="256"
            type="password"
            autocomplete="new-password"
            labelGridClass="inputLabel"
            placeholder=""
            :isRequired="true"
            @check = 'passCheck'
            :class="!changePassword && (id !== null) && 'grayscale'"
            :disabled="!changePassword && (id !== null)"
            :errMessage="errors.password || []"
            v-model="password"
          >
          </v-form-text>
          <v-form-text
            itemStyle="mt-2"
            label="パスワード確認"
            maxLength="256"
            type="password"
            autocomplete="new-password"
            labelGridClass="inputLabel"
            placeholder=""
            :isRequired="true"
            @check = 'passCheck'
            :class="!changePassword && (id !== null) && 'grayscale'"
            :disabled="!changePassword && (id !== null)"
            :errMessage="errors.password_twice || []"
            v-model="password_twice"
          >
          </v-form-text>
          <hr>

          <v-form-text
            itemStyle="mt-2"
            label="所属機関"
            maxLength="100"
            labelGridClass="inputLabel"
            placeholder="例）xxxx大学 大学院工学研究科"
            :errMessage="errors.affiliated_institution || []"
            v-model="affiliated_institution"
          >
          </v-form-text>
          <hr>

          <v-form-text
            itemStyle="mt-2"
            label="役職"
            maxLength="50"
            labelGridClass="inputLabel"
            placeholder="例）教授"
            :errMessage="errors.position || []"
            v-model="position"
          >
          </v-form-text>
          <hr>

          <b-form-checkbox
            v-model="changeTerm"
          >
            ログイン可能期間を設定する
          </b-form-checkbox>
          <div class="calendarWrap">
            <v-form-calendar
              label="ログイン可能期間"
              input-class="pickerDateForm"
              labelClass="left"
              itemStyle="line"
              v-model="allowed_from"
              class="container-fluid itemWrap calendarWrap"
              :class="!changeTerm && 'grayscale'"
              :disabled="!changeTerm"
              :errMessage="errors.allowed_from || []"
              :isRequired="false"
              :hasBadge="true"
              :noMsg="true">
            </v-form-calendar>
            <v-form-calendar
              input-class="pickerDateForm"
              v-model="allowed_to"
              itemStyle="line"
              class="dismissal"
              :class="!changeTerm && 'grayscale'"
              :disabled="!changeTerm"
              :errMessage="errors.allowed_to || []"
              :noMsg="true">
            </v-form-calendar>
          </div>
          <div v-if="errors.allowed_from"
            class="error-term">
            {{errors.allowed_from[0]}}
          </div>
          <div v-if="errors.allowed_to"
            class="error-term">
            {{errors.allowed_to[0]}}
          </div>
          <hr>

          <b-container fluid class="itemWrap permission">
            <b-row class="mt-2">
              <div class="inputLabel align-items-start permission-label">
                <div class="badgeWrap"><div class="mr-2 badge badge-required">必須</div></div>
                <label class="bold">権限</label>
              </div>
              <div
                class="authList">
                <div
                  v-for="(auth, key) in authList" :key="`auth_${key}`">
                  <b-form-checkbox
                    v-model="authes"
                    :value="auth.value"
                    class="inlineBlock"
                    :state="state"
                    stacked
                  >
                    {{auth.text}}
                  </b-form-checkbox>
                  <!-- <div class="university-select-wrap inlineBlock"
                    v-if="auth.value===5">
                    <b-form-select
                      :options="['XX大学']"/>
                  </div> -->
                </div>
              </div>
            </b-row>
          </b-container>
        </b-form>
      </div>

      <div class="bottonArea mt-4 flex flexCenter">
        <b-button class="btn btn-lg mr-2 bold" to="/cms/cmsUser/list">一覧に戻る</b-button>
        <b-button
          @click="store"
          variant="info"
          :disabled=!isSend
          class="btn btn save btn-primary bold"
        >{{ title }}</b-button>
        <b-button
          v-show="id"
          @click="destroy"
          class="btn btn-dark btn-lg ml-2 bold"
        >削除</b-button>
      </div>
    </section>

    <v-cant-edit
      v-else
      :sei=sei
      :mei=mei
      :mail_address=mail_address
      :affiliated_institution=affiliated_institution
      :position=position
      :allowed_from=allowed_from
      :allowed_to=allowed_to
      :authes=authes
      :authItems=authItems
    />
  </div>
</template>

<script>
import CONST_AUTH from '@/constants/auth';
import api from '@/modules/api';
import Secretariat from '@/views/cms/cmsUser/Secretariat.vue';

export default {
  name: 'CmsUserEditView',
  components: {
    'v-cant-edit': Secretariat,
  },
  data() {
    return {
      title: '新規登録',
      id: null,
      currentEmail: null,
      authList: [],
      authItems: [],
    };
  },
  computed: {
    state() {
      return this.authes.length > 0;
    },
    hasGroupAuth() {
      return this.$store.state.auth.authes.includes(CONST_AUTH.AUTH.GROUP);
    },
    sei: {
      get() {
        return this.$store.state.cmsUser.inputData.sei;
      },
      set(value) {
        this.$store.commit('cmsUser/updateFamilyName', value);
        this.$store.dispatch('cmsUser/checkMarks', { id: this.id });
      },
    },
    mei: {
      get() {
        return this.$store.state.cmsUser.inputData.mei;
      },
      set(value) {
        this.$store.commit('cmsUser/updateFirstName', value);
        this.$store.dispatch('cmsUser/checkMarks', { id: this.id });
      },
    },
    kana_sei: {
      get() {
        return this.$store.state.cmsUser.inputData.kana_sei;
      },
      set(value) {
        this.$store.commit('cmsUser/updateFamilyNameKana', value);
        this.$store.dispatch('cmsUser/checkMarks', { id: this.id });
      },
    },
    kana_mei: {
      get() {
        return this.$store.state.cmsUser.inputData.kana_mei;
      },
      set(value) {
        this.$store.commit('cmsUser/updateFirstNameKana', value);
        this.$store.dispatch('cmsUser/checkMarks', { id: this.id });
      },
    },
    mail_address: {
      get() {
        return this.$store.state.cmsUser.inputData.mail_address;
      },
      set(value) {
        this.$store.commit('cmsUser/updateMail_address', value);
        this.$store.dispatch('cmsUser/checkMarks', { id: this.id });
      },
    },
    affiliated_institution: {
      get() {
        return this.$store.state.cmsUser.inputData.affiliated_institution;
      },
      set(value) {
        this.$store.commit('cmsUser/updateAffiliated_institution', value);
      },
    },
    position: {
      get() {
        return this.$store.state.cmsUser.inputData.position;
      },
      set(value) {
        this.$store.commit('cmsUser/updatePosition', value);
      },
    },
    password: {
      get() {
        return this.$store.state.cmsUser.inputData.password;
      },
      set(value) {
        this.$store.commit('cmsUser/updatePassword', value);
        this.$store.dispatch('cmsUser/checkMarks', { id: this.id });
      },
    },
    password_twice: {
      get() {
        return this.$store.state.cmsUser.inputData.password_twice;
      },
      set(value) {
        this.$store.commit('cmsUser/updatePassword_twice', value);
        this.$store.dispatch('cmsUser/checkMarks', { id: this.id });
      },
    },
    allowed_from: {
      get() {
        return this.$store.state.cmsUser.inputData.allowed_from;
      },
      set(value) {
        this.$store.commit('cmsUser/updateAllowed_from', value);
      },
    },
    allowed_to: {
      get() {
        return this.$store.state.cmsUser.inputData.allowed_to;
      },
      set(value) {
        this.$store.commit('cmsUser/updateAllowed_to', value);
      },
    },
    authes: {
      get() {
        return this.$store.state.cmsUser.inputData.authes;
      },
      set(value) {
        this.$store.commit('cmsUser/updateAuthes', value);
        this.$store.dispatch('cmsUser/checkMarks', { id: this.id });
      },
    },
    changeEmail: {
      get() {
        return this.$store.state.cmsUser.inputData.changeEmail;
      },
      set(value) {
        this.$store.commit('cmsUser/isChangeEmail', value);
        this.$store.dispatch('cmsUser/resetItem', { name: 'email', value });
        this.$store.dispatch('cmsUser/checkMarks', { id: this.id });
      },
    },
    changePassword: {
      get() {
        return this.$store.state.cmsUser.inputData.changePassword;
      },
      set(value) {
        this.$store.commit('cmsUser/isChangePassword', value);
        this.$store.dispatch('cmsUser/resetItem', { name: 'password', value });
        this.$store.dispatch('cmsUser/checkMarks', { id: this.id });
      },
    },
    changeTerm: {
      get() {
        return this.$store.state.cmsUser.inputData.changeTerm;
      },
      set(value) {
        this.$store.commit('cmsUser/isChangeTerm', value);
      },
    },
    errors() {
      return this.$store.state.cmsUser.errors;
    },
    isSend() {
      return this.$store.state.cmsUser.isSend;
    },
  },
  methods: {
    // チェック用の権限取得
    async getAuth() {
      const auth = await api
        .send('/api/user/cms/auth')
        .catch(() => false);
      if (auth) {
        this.authList = auth.data.authList;
        this.authItems = auth.data.authItems;
      }
    },
    // ユーザーNoがある場合は更新画面
    async edit() {
      const items = await api
        .send(`/api/user/cms/edit/${this.id}`)
        .catch(async () => {
          await this.alert('ユーザーのデータを取得できませんでした。', false);
          window.location.assign('/cms/cmsUser/list');
        });
      this.$store.dispatch('cmsUser/editItem', items.data.editList);
      this.currentEmail = this.mail_address;
      this.$store.dispatch('page/offLoading');
    },
    // 登録更新
    async store() {
      this.$store.dispatch('page/onWaiting');
      const params = this.$store.state.cmsUser.inputData;
      const res = await api
        .send(`/api/user/cms/store/${this.id || 'create'}`, params)
        .catch((error) => {
          this.$store.commit('cmsUser/setErrorMsg', error.response.data.message);
          return false;
        });
      if (res !== false) {
        await this.alert('登録が完了しました。', false);
        // 自信ユーザー更新時にログアウトされないようにlogin_idを更新
        if (res.data.Success.isMine) {
          this.$store.dispatch('auth/authChange', res.data.Success.login_id);
        }
        window.location.assign(`/cms/cmsUser/edit/${res.data.Success.id}`);
      }
      this.$store.dispatch('page/offWaiting');
    },
    // 削除
    async destroy() {
      if (!await this.confirm('管理者から削除しますか？', false)) return;
      this.$store.dispatch('page/onWaiting');
      const del = await api
        .send(`/api/user/cms/destroy/${this.id}`)
        .catch(async (error) => {
          await this.alert(error.response.data.message, false);
        });
      if (del !== false) {
        await this.alert('削除が完了しました。', false);
        window.location.assign('/cms/cmsUser/list');
      }
      this.$store.dispatch('page/offWaiting');
    },
    passCheck() {
      this.$store.commit('cmsUser/deleteErrMsg', 'password_twice');
      if (this.password !== this.password_twice) {
        this.$store.commit('cmsUser/addErrorMsg', {
          key: 'password_twice',
          msg: ['パスワードの確認とパスワードが一致するよう入力してください。'],
        });
      }
    },
  },
  // ロード画面
  created() {
    this.$store.dispatch('page/onLoading');
    this.$store.dispatch('cmsUser/resetErrorMsg');
    this.$store.dispatch('cmsUser/init');

    this.id = this.$route.params.cms_user_id || null;
    this.getAuth();
    if (this.id) {
      this.title = '編集';
      this.edit();
    } else {
      this.$store.dispatch('page/offLoading');
    }
  },
};
</script>

<style>
  .cmsUserEdit .contentsWidth {
    background: #FFF;
    padding: 2rem;
    width: 1080px;
  }
  .cmsUserEdit .title {
    margin-bottom: 0;
  }
  .cmsUserEdit .subject {
    border-bottom: .1rem solid #A0A9D0;
    margin: 3rem auto 0;
    width: 1080px;
  }
  .cmsUserEdit input {
    height: 50px;
    font-size: 16px;
  }
  .cmsUserEdit .inputLabel {
    justify-content: flex-start;
  }
  .cmsUserEdit form .row {
    margin: 0;
  }
  .cmsUserEdit .itemWrap {
    margin: 15px 0;
    width: 100%;
  }
  .cmsUserEdit .itemWrap .textWrap {
    padding-left: 15px;
  }
  .cmsUserEdit .preEmail {
    width: 300px;
    font-weight: bold;
    white-space: pre-line;
    padding-left: 60px;
  }
  .permission-label {
    margin: 0 0 0 15px;
    width: 300px;
  }
  .cmsUserEdit .preEmail + div {
    word-break: break-word;
  }
  .cmsUserEdit .grayscale input {
    background: #F3F3F3;
    color: #F3F3F3;
  }
  .cmsUserEdit .grayscale {
    color: #3333334d
  }
  .cmsUserEdit .vdp-datepicker {
    width: 220px;
    padding-left: 15px;
  }
  .cmsUserEdit .dismissal {
    position: absolute;
    top: -0.6rem;
    left: 32rem;
  }
  .cmsUserEdit .dismissal::before {
    content: "～";
    float: left;
    margin-top: 1.3rem;
    margin-left: 40px;
  }
  .cmsUserEdit .calendarWrap {
    position: relative;
  }
  .cmsUserEdit .calendarWrap label {
    font-weight: 600;
  }
  .cmsUserEdit .calendarWrap.errItem {
    margin-bottom: 50px;
  }
  .cmsUserEdit .calendarWrap .error {
    position: absolute;
  }
  .cmsUserEdit .errItem + .dismissal {
    top: -0.6rem;
    left: 28rem;
  }
  .cmsUserEdit .dismissal .right {
    display: none;
  }
  .cmsUserEdit input[type="checkbox"] + label {
    display: flex;
    align-items: center;
    min-height: 1.5rem;
    margin-left: 329px;
  }
  .cmsUserEdit .permission label.bold {
    margin-top: 2px;
  }
  .cmsUserEdit .permission input[type="checkbox"] + label {
    margin-left: 0;
  }
  .cmsUserEdit .attention::before {
    content: '英大文字・英小文字・数字・記号をそれぞれ1つ以上利用し8文字以上入力してください。';
    font-size: 13px;
    margin-left: 251px;
    width: 524px;
  }
  .cmsUserEdit .error {
    margin-left: 15px;
    padding-left: 0;
  }
  .cmsUserEdit .errItem input.form-control {
    border: solid 1px #dc3545 !important
  }
  .cmsUserEdit .custom-control-input.is-invalid ~ .custom-control-label,
  .cmsUserEdit .custom-control-input.is-valid ~ .custom-control-label {
    color: #333;
  }
  .university-select-wrap {
    width: 200px;
    margin-left: 10px;
  }
  .university-select-wrap>select {
    height: 40px;
  }

  .error-term {
    font-size: 12.8px;
    color: #dc3545;
    display: block;
    width: 400px;
    margin-top: 3px;
    margin-left: 350px;
  }
</style>
